// zh.js
export default {
  home: {
    title: '首页',
    slogan: '安全第一\n' + '服务至上\n' + '以客为尊\n' + '宾至如归',
    useCar: '用车',
    aboutUs: {
      title: '关于我们',
      content1: '以“服务至上，宾至如归“为公司理念，为您提供专业、高端、安全、细致入微的服务体验。',
      content2:
        '“株式会社DDハイヤー”是位于东京的拥有十年以上行业经验的正规专车公司。多种车型应对不同用车需求；车辆皆为高端配置，定期检修，保证您的用车安全；司机及工作人员严格培训上岗，服务让您安心、贴心、放心。',
      content3:
        '“株式会社DDハイヤー”为日本的专业国际化专车公司，公司有多国从业人员，提供日语、英语、中文、韩语等语言对应，海外客人也可安心享受。',
      content4:
        '提供空港接送、商务用车、观光用车、高尔夫接送等用车或包车服务，同时还有株式会社DDツーリズム为后盾，为您提供专业日本游览等服务。'
    }
  },
  navbar: {
    home: '首页',
    carIntroduction: '车种介绍',
    carService: '用车服务',
    addService: '旅行服务',
    clubsSummary: '公司概要',
    contactUs: '联系我们',
    language: '语言切换',
    charteredCar: '日本全境包车',
    serviceConfiguration: '服务配置',
    serviceAdvantage: '服务优势',
    exclusiveService: '专享服务',
    companyPhilosophy: '公司理念',
    companyProfile: '公司概要',
    serviceContent: '服务内容',
    onlineMessage: '在线留言',
    joinUs: '加入我们',
    commonQA: '常见Q&A',
    latest: '最新推送',
    companyName: '株式会社DD专车',
    address: '公司地址'
  },
  routeTitle: {
    home: '首页',
    carIntroduction: '车种介绍',
    carService: '用车服务',
    addService: '旅行服务',
    clubsSummary: '公司概要',
    contactUs: '联系我们',
    pushDetails: '推送详情'
  },
  common: {
    language: '语言',
    submit: '提交',
    order: '立即预约',
    details: '查看详情',
    onlineMsg: {
      process: '服务流程',
      message: '在线留言',
      step1:
        '填写表单，输入基础信息和联系方式，提交后，客服会第一时间联系您。您也可以通过电话直接与我们联系哦。',
      step2: '客服根据您的需求，为您定制服务后报价，确认好服务内容和价格后，支付服务费用。',
      step3: '全程无忧服务，司机主动与您联系，给予贴心陪伴的专车专享高端旅程。'
    },
    successMsg: {
      title: '提交成功'
    },
    formMsg: {
      baseInfo: '基本信息',
      rlus: '请输入',
      rlusSelect: '请选择',
      name: {
        label: '姓名',
        placeholder: '请输入您的姓名，例)山田 太郎'
      },
      email: {
        label: '电子邮件',
        placeholder: '请输入邮箱地址，例)xxxabytedance.',
        rlus: '请输入正确邮箱地址'
      },
      tel: {
        label: '联系电话',
        placeholder: '支持海外号码，例)120666131',
        rlus: '请输入正确电话号码'
      },
      demand: '用车需求',
      address: {
        label: '出发地',
        placeholder: '其他地点请输入',
        option: ['成田空港', '羽田空港', '其他空港']
      },
      expectedDate: {
        label: '期望日期',
        placeholder: '请选择日期，年/月/日'
      },
      flight: {
        label: '预订航班',
        placeholder: '例)NH104'
      },
      destination: {
        label: '目的地',
        placeholder: '例)东京都千代田区'
      },
      peopleNumber: {
        label: '人数',
        placeholder: '请填写乘车人数'
      },
      luggageNumber: {
        label: '行李数',
        placeholder: '请填写行李数'
      },
      contactInformation: {
        label: '首选联系方式',
        placeholder: ''
      },
      pickUpService: {
        label: '接机服务',
        placeholder: '接机服务是指司机持名牌在机场大门出口等候的服务'
      },
      childSeat: {
        label: '儿童座椅',
        placeholder: ''
      },
      language: {
        label: '英语/韩语',
        placeholder: ''
      },
      remark: {
        label: '备注',
        placeholder: '如您有其他需求，请输入',
        rlus: '如您有其他需求，请输入'
      },
      addService: '附加需求',
      exclusiveService: {
        label: '专享服务',
        placeholder: ''
      },
      detailService: {
        label: '具体要求',
        placeholder: '请输入，如预订的时间、地点、特殊要求等'
      }
    }
  },
  carIntroduction: {
    maximumPassengers: '最多搭乘',
    maximumPassengersLabel: '乘客',
    maximumPassengersUnit: '人',
    highestPlacement: '最高放置',
    highestPlacementLabel: '行李',
    highestPlacementUnit: '件',
    lowestPrice: '价格低至',
    otherBenefits: '其他福利',
    swiper1: '专业客制行程规划',
    swiper2: '宾至如归尊荣礼遇',
    swiper3: '资深团队全程安心',
    swiper4: '定期健检安全舒适'
  },
  carService: {
    title: '用车服务',
    swiper1: {
      label: '机场接送',
      desc: '出机口接机牌接机服务，\n' + '无须担心航班延误'
    },
    swiper2: {
      label: '商务用车',
      desc: '商务车型，专业司机，\n' + '车上提供免费WI-FI，出行、工作两不误'
    },
    swiper3: {
      label: '观光用车',
      desc:
        '日本全境观光用车，让旅行高效、舒适、便利，不必担心海外旅行的语言不通、行李不便、路线不熟等问题'
    },
    swiper4: {
      label: '高尔夫接送',
      desc: '出发地接至高尔夫球场，打球期间司机在外等候，不必担心球场远，球包重，让您轻享高尔夫之行'
    },
    swiper5: {
      label: '其他附加服务',
      desc: '提供行程定制、订酒店、订餐厅、订球场、订门票等服务'
    },
    img1: {
      label: '高端车型',
      desc: '公司车辆皆为高端车型，定期检修，对车辆状态严格管理，更新淘汰，以保障用车安全、舒适。'
    },
    img2: {
      label: '豪华座舱',
      desc: '车辆座舱皆为豪华座舱，高端座椅，让您即使长途用车也不会过于劳累，在座舱舒适休息。'
    },
    img3: {
      label: '干净舒适',
      desc: '我们实施一客一清的方式，保证每位客人用车前车辆都经过彻底清扫消毒。'
    },
    img4: {
      label: '金牌司机',
      desc: '司机皆经过专业且严格的培训，服务让您安心、贴心、放心。'
    },
    chooseUs: {
      title: '为什么选择我们？',
      text1: '全部车型配备免费Wi-Fi',
      text2: '专业培训 服务至上',
      text3: '安心、安全、舒适',
      text4: '不必担心延误等突发状况',
      text5: '抵达大厅享受迎接服务'
    }
  },
  addService: {
    swiper1: {
      title: '旅行私人订制',
      desc:
        '个性化私人订制行程，让\n' +
        '您旅途无忧。专属行程定\n' +
        '制师，细心沟通，耐心解\n' +
        '答，尽享随心日本之旅。'
    },
    swiper2: {
      title: '酒店预订',
      desc:
        '提前预订入住酒店及房\n' +
        '型，确保出行住宿无忧。\n' +
        '根据您的喜好提供酒店建\n' +
        '议，给您最好的入住体\n' +
        '验。'
    },
    swiper3: {
      title: '餐厅预订',
      desc:
        '预订日本米其林、日料等\n' +
        '美食餐位，餐位无忧，让\n' +
        '您用车出行同时享受地道\n' +
        '美食，尊贵用餐体验。'
    },
    swiper4: {
      title: '高尔夫球预订',
      desc:
        '提前规划高尔夫球场使用\n' +
        '时间，享受专业球场设施\n' +
        '与服务畅享高尔夫运动乐\n' +
        '趣。'
    },
    swiper5: {
      title: '各种门票预订',
      desc:
        '一站式服务，涵盖各类活\n' +
        '动门票预订，轻松获取心\n' +
        '仪活动的入场资格，享受\n' +
        '便捷、安全的购票体验!'
    },
    info: '更多服务需求，欢迎联系我们！'
  },
  clubsSummary: {
    info: {
      title: '株式会社DD专车',
      desc:
        '具有十年以上行业经验的东京专业专车公司，\n' +
        '以“服务至上，宾至如归”为公司理念，为您提供专业、高端、安全、细致入微的服务体验。\n' +
        '日本专业国际化专车公司，公司有多国从业人员，提供日语、英语、中文、韩语等语言对应，海外客人也可安心享受。'
    },
    summary: {
      title: '公司概要',
      headQuarters: {
        label: '本社',
        content: '株式会社DDツーリズム'
      },
      representative: {
        label: '代表者',
        content: '望月 輝石'
      },
      headOffice: {
        label: '本社住所',
        content:
          '〒101-0021東京都千代田区外神田3丁目5番5号\n' +
          'TEL：03-6262-9696\n' +
          'FAX：03-6262-9630'
      },
      permitApprovalNumber: {
        label: '許可認可番号',
        content: '東京都知事登録旅行業2-8357'
      },
      managementCompany: {
        label: '運営会社',
        content: '株式会社DDハイヤー'
      },
      representative2: {
        label: '代表者',
        content: '淺井 漢鷹'
      },
      salesOffice: {
        label: '営業所',
        content:
          '〒133-0055東京都江戸川区西篠崎2丁目19番9号\n' +
          'TEL：03-5664-6122\n' +
          'FAX：03-5664-6132'
      },
      permitApprovalNumber2: {
        label: '許可認可番号',
        content: '関自旅ニ第762号'
      }
    },
    address: {
      title: '公司地址',
      info1: {
        name: '本社\n' + '株式会社DDツーリズム',
        detail: '東京都千代田区外神田3丁目5番5号',
        tel: 'TEL：03-6262-9696'
      },
      info2: {
        name: '営業所\n' + '株式会社DDハイヤー',
        detail: '東京都江戸川区西篠崎2丁目19番9号',
        tel: 'TEL：03-5664-6122'
      }
    },
    service: {
      title: '用车服务',
      line1: {
        swiper1: {
          title: '机场接送',
          desc: '出机口接机牌接机服务，无须担心航班延误'
        },
        swiper2: {
          title: '商务用车',
          desc: '商务车型，专业司机，车上提供免费WI-FI，出行、工作两不误'
        },
        swiper3: {
          title: '观光用车',
          desc:
            '日本全境观光用车，让旅行高效、舒适、便利，不必担心海外旅行的语言不通、行李不便、路线不熟等问题。'
        },
        swiper4: {
          title: '高尔夫接送',
          desc:
            '出发地接至高尔夫球场，打球期间司机在外等候，不必担心球场远，球包重，让您轻享高尔夫之行。'
        },
        swiper5: {
          title: '其他附加服务',
          desc: '提供行程定制、订酒店、订餐厅、订球场、订门票等服务'
        }
      },
      line2: {
        swiper1: {
          title: '旅行私人订制',
          desc:
            '个性化私人订制行程，让您旅途无忧。专属行程定制师，细心沟通，耐心解答，尽享随心日本之旅。'
        },
        swiper2: {
          title: '酒店预订',
          desc:
            '提前预订入住酒店及房型，确保出行住宿无忧。根据您的喜好提供酒店建议，给您最好的入住体验。'
        },
        swiper3: {
          title: '餐厅预订',
          desc:
            '预订日本米其林、日料等美食餐位，餐位无忧，让您用车出行同时享受地道美食，尊贵用餐体验。'
        },
        swiper4: {
          title: '高尔夫球预订',
          desc: '提前规划高尔夫球场使用时间，享受专业球场设施与服务畅享高尔夫运动乐趣。'
        },
        swiper5: {
          title: '各种门票预订',
          desc:
            '一站式服务，涵盖各类活动门票预订，轻松获取心仪活动的入场资格，享受便捷、安全的购票体验!'
        }
      },
      title2: '专享服务'
    }
  },
  pushDetails: {
    backPage: '回到首页',
    recentPush: '近期推送',
    previous: '上一篇',
    next: '下一篇'
  },
  contactUs: {
    JoinUs: '加入我们',
    commonQA: '常见Q&A'
  },
  footer: {
    address: '〒101-0021東京都千代田区外神田3丁目5番5号'
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navbar flex row-between col-center",class:_vm.lang,style:({
    background:
      _vm.$route.path === '/' || _vm.$route.path === '/home'
        ? _vm.showLogo
          ? 'rgba(0, 0, 0, 0.40)'
          : ''
        : 'rgba(0, 0, 0, 0.40)'
  })},[_c('div',{staticClass:"flex col-center youShe"},[(_vm.showLogo || !(_vm.$route.path === '/' || _vm.$route.path === '/home'))?_c('img',{class:_vm.$route.path === '/' || _vm.$route.path === '/home' ? 'home' : '',attrs:{"src":require("../../assets/images/logo.png"),"alt":""}}):_vm._e()]),_c('div',{staticClass:"menu-box flex"},_vm._l((_vm.menuList),function(item,index){return _c('div',{key:item.title,staticClass:"menu flex col-center",on:{"click":function($event){return _vm.toMenu(item)},"mouseleave":_vm.outTitle}},[_c('div',{staticClass:"title",class:_vm.$route.path.indexOf(item.url) !== -1 ||
          (_vm.$route.path === '/' && item.title === _vm.$i18n.t('navbar.home'))
            ? 'act'
            : '',on:{"mouseenter":function($event){return _vm.hoverTitle(index)}}},[(index === _vm.menuList.length - 1)?[_vm._v(" "+_vm._s(_vm.lang === 'ja' ? '日本語' : _vm.lang === 'en' ? 'English' : _vm.lang === 'zh' ? '中文（简体）' : '中文（繁體）')+" ")]:[_vm._v(" "+_vm._s(item.title)+" ")]],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index === _vm.hoverIndex),expression:"index === hoverIndex"}],class:item.children.length > 0 ? 'menu-children' : ''},_vm._l((item.children),function(child,i){return _c('div',{key:i,staticClass:"child-title flex col-center row-center",class:_vm.menuList.length - 1 === index ? child.styleClass : '',on:{"click":function($event){$event.stopPropagation();return _vm.toChildMenu(item, child)}}},[_vm._v(" "+_vm._s(child.title)+" ")])}),0)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }
// en.js
export default {
  home: {
    title: 'home',
    slogan:
      'Safety first\n' + 'Service supreme\n' + 'Customer top \n' + 'priority\n' + 'Feel at home',
    useCar: 'Use car',
    aboutUs: {
      title: 'About us',
      content1:
        'We provide professional, high-quality, safe, and attentive service based on our philosophy of “giving top priority to customer feedback and providing the best possible service. ',
      content2:
        'DD Hire Co., Ltd. is a Tokyo-based hire service company with over 10 years of experience. We have a wide range of car models to meet various needs. All vehicles are equipped with high-end features and are regularly inspected to ensure the safety of our clients. Our drivers and staff go through rigorous training before working for us so that we can provide safe and reliable service.',
      content3:
        'DD Hire Inc. has a global perspective and a multi-national staff. We can provide services in Japanese, English, Chinese, Korean, and other languages, allowing our overseas customers to use our services with peace of mind.',
      content4:
        'We can arrange airport transfers, business trip use, sightseeing use, golf transfers, and other uses and charter trucks. We can also provide professional Japan tour services backed up by DD Tourism Co.'
    }
  },
  navbar: {
    home: 'TOP',
    carIntroduction: 'Car Models',
    carService: 'Car Service',
    addService: 'Travel Service',
    clubsSummary: 'Company Profile',
    contactUs: 'Contact Us',
    language: 'Language',
    charteredCar: 'Japan Nationwide Hire',
    serviceConfiguration: 'Services',
    serviceAdvantage: 'Service Superiority',
    exclusiveService: 'Exclusive Service',
    companyPhilosophy: 'Company Philosophy',
    companyProfile: 'Company Profile',
    serviceContent: 'Services',
    onlineMessage: 'Online Message',
    joinUs: 'Join Us',
    commonQA: 'Frequently Asked Q&A',
    latest: 'Latest Push',
    companyName: 'DD Tourism Co. Ltd',
    address: 'Company Address'
  },
  routeTitle: {
    home: 'HOME',
    carIntroduction: 'VEHICLE TYPE',
    carService: 'SERVICES',
    addService: 'EXCLUSIVE',
    clubsSummary: 'ABOUT DD',
    contactUs: 'CONTACT US',
    pushDetails: 'Push Details'
  },
  common: {
    language: 'language',
    submit: 'Submit',
    order: 'Reservation Now',
    onlineMsg: {
      process: 'Service Process',
      message: 'Online Message',
      step1:
        'Fill out the form, input your basic information and contact details, and submit. Our customer service will contact you promptly. You can also contact us directly by phone.',
      step2:
        "Our customer service will customize services based on your needs, provide a quotation, and once the service details and price are confirmed, you'll make the payment.",
      step3:
        'Enjoy worry-free service throughout the journey. Our drivers will proactively communicate with you, providing attentive support for a premium travel experience in dedicated vehicles.'
    },
    successMsg: {
      title: 'Submission successful'
    },
    formMsg: {
      baseInfo: 'Basic Information',
      rlus: 'please input',
      rlusSelect: 'Please select',
      name: {
        label: 'Name',
        placeholder: 'Please enter your name. Example: Yamada Taro'
      },
      email: {
        label: 'Email',
        placeholder: 'Please enter your email address. Example: xxx@bytedance.com',
        rlus: 'Please enter the correct email address'
      },
      tel: {
        label: 'Phone Number',
        placeholder: 'Supports international numbers. Example: 120666131',
        rlus: 'Please enter the correct phone number'
      },
      demand: 'Vehicle Needs',
      address: {
        label: 'Departure Location',
        placeholder: 'Please enter other locations',
        option: ['Narita Airport', ' Haneda Airport ', 'Other Airport']
      },
      expectedDate: {
        label: 'Desired Date',
        placeholder: 'Please select the date. Year/Month/Day Evening'
      },
      flight: {
        label: 'Flight Reservation',
        placeholder: 'Example: NH104'
      },
      destination: {
        label: 'Destination',
        placeholder: 'Example: Chiyoda-ku, Tokyo'
      },
      peopleNumber: {
        label: 'Number of Passengers',
        placeholder: 'Please enter the number of passengers'
      },
      luggageNumber: {
        label: 'Number of Luggage',
        placeholder: 'Please enter the number of luggage'
      },
      contactInformation: {
        label: 'Preferred Contact Method',
        placeholder: ''
      },
      pickUpService: {
        label: 'Pickup Service',
        placeholder:
          'Pickup service means the driver will wait at the airport exit with a name sign'
      },
      childSeat: {
        label: 'Child Seat',
        placeholder: ''
      },
      language: {
        label: 'English/Korean',
        placeholder: ''
      },
      remark: {
        label: 'Remarks',
        placeholder: 'If you have any other requirements, please enter'
      },
      addService: 'Other Needs',
      exclusiveService: {
        label: 'Additional Requests',
        placeholder: ''
      },
      detailService: {
        label: 'Requirements',
        placeholder: 'Please enter, such as the scheduled time, place, special requirements, etc'
      }
    }
  },
  carIntroduction: {
    maximumPassengers: 'Maximum Passengers',
    maximumPassengersLabel: 'Passenger',
    maximumPassengersUnit: 'person',
    highestPlacement: 'Highest Placement',
    highestPlacementLabel: 'Luggage',
    highestPlacementUnit: 'piece',
    lowestPrice: 'Lowest Price',
    otherBenefits: 'Other Benefits',
    swiper1: 'Professional customized\n' + 'itinerary planning',
    swiper2: 'At-home feeling \n' + 'with honor and privilege',
    swiper3: 'Peace of mind \n' + 'from our experienced team',
    swiper4: 'Regular car checkups \n' + 'for safety and comfort'
  },
  carService: {
    title: 'Car Services',
    swiper1: {
      label: 'Airport \n' + 'Transfers',
      desc:
        'No need to worry about flight delays because you are picked up at the departure gate with a pick-up sign.'
    },
    swiper2: {
      label: 'Business Trip Use',
      desc:
        'Business models, professional drivers, free WI-FI in the car, travel and work both sides of the equation.'
    },
    swiper3: {
      label: 'Sightseeing Use',
      desc:
        'Travel efficiently, comfortably and conveniently with a car for sightseeing throughout Japan, without having to worry about language barriers, inconvenient luggage, or unfamiliar routes when traveling overseas.'
    },
    swiper4: {
      label: 'Golf  Transfers',
      desc:
        'Pick up at the departure point to the golf course, the driver waits outside during the play, no worry about the course is far, the ball bag is heavy, you can enjoy the golf trip lightly.'
    },
    swiper5: {
      label: 'Other Uses',
      desc:
        'Provide trip customization, hotel booking, restaurant booking, stadium booking, ticket booking and other services.'
    },
    img1: {
      label: 'High End Models',
      desc:
        'Our cars are all high-end models, regular maintenance, strict management of the state of the vehicle, update the elimination, in order to ensure the safety and comfort.'
    },
    img2: {
      label: 'Deluxe Cabin',
      desc:
        'Vehicle cabin are luxury cabin, high-end seats, so that even if use the car for a long distance, you will not be too tired. Comfortable rest in the cabin.'
    },
    img3: {
      label: 'Clean and \n' + 'Comfortable',
      desc:
        "We implement a one-guest-one-clean method to ensure that the vehicle is thoroughly cleaned and disinfected before each guest's use."
    },
    img4: {
      label: 'Professional Driver',
      desc:
        'Our drivers are all professionally and strictly trained, and the service provides you with peace of mind, intimacy and peace of mind.'
    },
    chooseUs: {
      title: 'Why choose us?',
      text1: 'Free wifi in all car models',
      text2: 'Professional training service first',
      text3: 'Safe, secure, and comfortable',
      text4: 'There is no need to worry \n' + 'about delays or other unexpected situations',
      text5: 'Greeting service in the arrival hall'
    }
  },
  addService: {
    swiper1: {
      title: 'Travel Plan \n' + 'OrderMade',
      desc:
        'Personalized private \n' +
        'customized itinerary, let \n' +
        'you travel without \n' +
        'worry. Dedicated \n' +
        'itinerary customizer, \n' +
        'careful communication, \n' +
        'patient answer, enjoy \n' +
        'the Japan trip at your \n' +
        'own pace.'
    },
    swiper2: {
      title: 'Hotel Reservation',
      desc:
        'Book your hotel and \n' +
        'room type in advance to \n' +
        'ensure worry-free travel \n' +
        'and accommodation. \n' +
        'Provide hotel \n' +
        'suggestions based on \n' +
        'your preferences to give \n' +
        'you the best stay \n' +
        'experience.'
    },
    swiper3: {
      title: 'Restaurant \n' + 'Reservations',
      desc:
        'Reservation of \n' +
        'Japanese Michelin, \n' +
        'Japanese food and \n' +
        'other gourmet meals, \n' +
        'meals without worry, so \n' +
        'that you use the car to \n' +
        'travel at the same time \n' +
        'to enjoy the authentic \n' +
        'food, honored dining \n' +
        'experience.'
    },
    swiper4: {
      title: 'Golf Reservations',
      desc:
        'Plan your golf course \n' +
        'usage time in advance, \n' +
        'enjoy professional \n' +
        'course facilities and \n' +
        'services, and enjoy the \n' +
        'fun of golf.'
    },
    swiper5: {
      title: 'Various Ticket\n' + 'Reservations',
      desc:
        'One-stop service \n' +
        'covering ticket booking \n' +
        'for all kinds of events, \n' +
        'easily get admission to \n' +
        'your favorite events and \n' +
        'enjoy a convenient and \n' +
        'safe ticketing \n' +
        'experience!'
    },
    info: 'For more service needs, please feel free to contact us.'
  },
  clubsSummary: {
    info: {
      title: 'DD Hire Co. Ltd',
      desc:
        'We are professional hire company based in Tokyo with more than 10 years of experience. We provide professional, \n' +
        "high-end, safe and meticulous service  with the company's philosophy of “service first to let our guests feel at home”.\n" +
        'We are a professional and internationalized chauffeur hire company in Japan with multinational staff, speaking \n' +
        'Japanese, English, Chinese, Korean and other languages, so that overseas guests can enjoy the service with peace of mind.'
    },
    summary: {
      title: 'Company Profile',
      headQuarters: {
        label: 'Head Quarters',
        content: 'DD Tourism Co. Ltd'
      },
      representative: {
        label: 'Representative',
        content: 'Mochizuki Kiseiki'
      },
      headOffice: {
        label: 'Head office',
        content:
          '3-5-5 Sotokanda, Chiyoda-ku, Tokyo 101-0021, Japan\n' +
          'TEL：03-6262-9696\n' +
          'FAX：03-6262-9630'
      },
      permitApprovalNumber: {
        label: 'Permit approval number',
        content: 'Tokyo Governor Registered Travel Agency No. 2-8357'
      },
      managementCompany: {
        label: 'Management Company',
        content: 'DD Hire Co. Ltd'
      },
      representative2: {
        label: 'Representative',
        content: 'Asai Kantaka'
      },
      salesOffice: {
        label: 'Sales office',
        content:
          '2-19-9 Nishi-Shinozaki, Edogawa-ku, Tokyo 133-0055, Japan\n' +
          'TEL：03-5664-6122\n' +
          'FAX：03-5664-6132'
      },
      permitApprovalNumber2: {
        label: 'Permit approval number',
        content: 'Kanto Transportation For Passengers No. 2-762'
      }
    },
    address: {
      title: 'Company Address',
      info1: {
        name: 'Head Quarters\n' + 'DD Tourism Co. Ltd',
        detail: 'Sotokanda, Chiyoda-ku, \n' + 'Tokyo 101-0021, Japan',
        tel: 'TEL：03-6262-9696'
      },
      info2: {
        name: 'Sales office\n' + 'DD Hire Co. Ltd',
        detail: 'Nishi-Shinozaki, Edogawa-ku, \n' + 'Tokyo 133-0055, Japan',
        tel: 'TEL：03-5664-6122'
      }
    },
    service: {
      title: 'Car Service',
      line1: {
        swiper1: {
          title: 'Airport Transfers',
          desc:
            'No need to worry about flight delays because you are picked up at the departure gate with a pick-up sign.'
        },
        swiper2: {
          title: 'Business Trip Use',
          desc:
            'Business models, professional drivers, free WI-FI in the car, travel and work both sides of the equation.'
        },
        swiper3: {
          title: 'Sightseeing Use',
          desc:
            'Travel efficiently, comfortably and conveniently with a car for sightseeing throughout Japan, without having to worry about language barriers, inconvenient luggage, or unfamiliar routes when traveling overseas.'
        },
        swiper4: {
          title: 'Golf Transfers',
          desc:
            'Pick up at the departure point to the golf course, the driver waits outside during the play, no worry about the course is far, the ball bag is heavy, you can enjoy the golf trip lightly.'
        },
        swiper5: {
          title: 'Other Uses',
          desc:
            'Provide trip customization, hotel booking, restaurant booking, stadium booking, ticket booking and other services.'
        }
      },
      line2: {
        swiper1: {
          title: 'Travel Plan\n' + 'Order Made',
          desc:
            'Personalized private customized itinerary, let you travel without worry. Dedicated itinerary customizer, careful communication, patient answer, enjoy the Japan trip at your own pace.'
        },
        swiper2: {
          title: 'Hotel Reservation',
          desc:
            'Book your hotel and room type in advance to ensure worry-free travel and accommodation. Provide hotel suggestions based on your preferences to give you the best stay experience.'
        },
        swiper3: {
          title: 'Restaurant\n' + 'Reservations',
          desc:
            'Reservation of Japanese Michelin, Japanese food and other gourmet meals, meals without worry, so that you use the car to travel at the same time to enjoy the authentic food, honored dining experience.'
        },
        swiper4: {
          title: 'Golf Reservations',
          desc:
            'Plan your golf course usage time in advance, enjoy professional course facilities and services, and enjoy the fun of golf.'
        },
        swiper5: {
          title: 'Various Ticket\n' + 'Reservations',
          desc:
            'One-stop service covering ticket booking for all kinds of events, easily get admission to your favorite events and enjoy a convenient and safe ticketing experience!'
        }
      },
      title2: 'Exclusive Service'
    }
  },
  pushDetails: {
    backPage: 'Back to homepage',
    recentPush: 'Recent posts',
    previous: 'Previous',
    next: 'Next'
  },
  contactUs: {
    JoinUs: 'Join Us',
    commonQA: 'Frequently Asked Q&A'
  },
  footer: {
    address: '3-5-5 Sotokanda, Chiyoda-ku, Tokyo 101-0021, Japan'
  }
}

// 引入vue-i18n
import VueI18n from 'vue-i18n'
import Vue from 'vue'

// element ui 国际化
import Element from 'element-ui'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import zhfLocale from 'element-ui/lib/locale/lang/zh-TW'
//自己的语言
import zh from './zh' // 中文
import en from './en' // 英文
import ja from './jpa' // 日文
import zhf from './zh_fan' // 中文繁体
// 注册
Vue.use(VueI18n)

// 语言包
// const messages = {
//   zh: require('./zh.js').lang, // 中文简体语言包
//   zhf: require('./zh_fan.js').lang, // 中文繁体语言包
//   en: require('./en.js').lang, // 英文语言包
//   jpa: require('./jpa.js').lang // 日文语言包
// }

// 配置i18n语言包
const messages = {
  en: {
    ...en,
    ...enLocale
  },
  zh: {
    ...zh,
    ...zhLocale
  },
  zhf: {
    ...zhf,
    ...zhfLocale
  },
  ja: {
    ...ja,
    ...jaLocale
  }
}

const i18n = new VueI18n({
  locale: localStorage.getItem('language') || 'ja', // 语言标识
  messages,
  silentTranslationWarn: true
})

// vue配置
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

//获取浏览器语言
export function getLang(time, fmt) {
  let lang = navigator.language
  if (lang === 'zh-HK' || lang === 'zh-TW') {
    lang = 'zhf'
  } else if (lang === 'zh' || lang === 'zh-CN') {
    lang = 'zh'
  } else if (lang === 'ja') {
    lang = 'ja'
  } else {
    lang = 'en'
  }
  return lang
}

export default i18n

import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n, { getLang } from '@/utils/lang'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/index/index'),
    name: 'Index',
    meta: { title: 'DD专车', key: 'home', keepAlive: true }
  },
  {
    path: '/home',
    component: () => import('@/views/index/index'),
    name: 'Home',
    meta: { title: 'DD专车', key: 'home', keepAlive: true }
  },
  {
    path: '/car-introduction',
    component: () => import('@/views/carIntroduction/index'),
    name: 'carIntroduction',
    meta: { title: '车种介绍', key: 'carIntroduction' }
  },
  {
    path: '/car-service',
    component: () => import('@/views/carService/index'),
    name: 'CarService',
    meta: { title: '用车服务', key: 'carService' }
  },
  {
    path: '/add-service',
    component: () => import('@/views/addService/index'),
    name: 'AddService',
    meta: { title: '附加服务', key: 'addService' }
  },
  {
    path: '/clubs-summary',
    component: () => import('@/views/clubsSummary/index'),
    name: 'ClubsSummary',
    meta: { title: '公司概要', key: 'clubsSummary' }
  },
  {
    path: '/contact-us',
    component: () => import('@/views/contactUs/index'),
    name: 'ContactUs',
    meta: { title: '联系我们', key: 'contactUs' }
  },
  {
    path: '/push-details',
    component: () => import('@/views/pushDetails/index'),
    name: 'PushDetails',
    meta: { title: '推送详情', key: 'pushDetails' }
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop
      }
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  },
  routes
})

//处理路由标题国际化
const getPageTitle = (i18n, meta) => {
  // i18n.locale = 'zhf'
  let lang = getLang()
  i18n.locale = localStorage.getItem('language') || lang
  const hasKey = i18n.te(`routeTitle.${meta.key}`)
  const title = i18n.t(`routeTitle.${meta.key}`)
  if (hasKey) {
    return title
  }
  return meta.title
}

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  if (location.split('?')[0] === window.location.pathname) {
    originalPush.call(this, location).catch(err => err)
    window.location.reload()
  } else {
    return originalPush.call(this, location).catch(err => err)
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = getPageTitle(i18n, to.meta)
  }
  return next()
})

export default router

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入国际化
import i18n from './utils/lang/index.js'
//引入element
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/scss/element-variables.scss'
import '@/assets/fonts/iconfont.css'
import('@/assets/fonts/font.scss')

// 引入全局css
import './assets/scss/reset.scss'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
//全局工具类
import util from '@/utils/index'
import scroll from 'vue-seamless-scroll'
// 或者你可以自己设置全局注册的组件名 默认注册的组件名是 vue-seamless-scroll
Vue.use(scroll, { componentName: 'scroll-seamless' })
Vue.prototype.$util = util
// 下载文件
import download from '@/utils/download'
Vue.prototype.$download = download
Vue.config.productionTip = false

Vue.use(Element, {
  size: 'medium' // set element-ui default size
})

router.beforeEach(async (to, from, next) => {
  next()
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

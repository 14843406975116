<template>
  <div
    class="navbar flex row-between col-center"
    :class="lang"
    :style="{
      background:
        $route.path === '/' || $route.path === '/home'
          ? showLogo
            ? 'rgba(0, 0, 0, 0.40)'
            : ''
          : 'rgba(0, 0, 0, 0.40)'
    }"
  >
    <div class="flex col-center youShe">
      <img
        v-if="showLogo || !($route.path === '/' || $route.path === '/home')"
        src="../../assets/images/logo.png"
        alt=""
        :class="$route.path === '/' || $route.path === '/home' ? 'home' : ''"
      />
      <!--      <div v-if="showLogo || !($route.path === '/' || $route.path === '/home')" class="ltd">-->
      <!--        DD HIRE co.,LTD.-->
      <!--      </div>-->
    </div>
    <div class="menu-box flex">
      <div
        @click="toMenu(item)"
        class="menu flex col-center"
        v-for="(item, index) in menuList"
        :key="item.title"
        @mouseleave="outTitle"
      >
        <div
          class="title"
          :class="
            $route.path.indexOf(item.url) !== -1 ||
            ($route.path === '/' && item.title === $i18n.t('navbar.home'))
              ? 'act'
              : ''
          "
          @mouseenter="hoverTitle(index)"
        >
          <template v-if="index === menuList.length - 1">
            {{
              lang === 'ja'
                ? '日本語'
                : lang === 'en'
                ? 'English'
                : lang === 'zh'
                ? '中文（简体）'
                : '中文（繁體）'
            }}
          </template>
          <template v-else>
            {{ item.title }}
          </template>
        </div>
        <div v-show="index === hoverIndex" :class="item.children.length > 0 ? 'menu-children' : ''">
          <div
            @click.stop="toChildMenu(item, child)"
            class="child-title flex col-center row-center"
            v-for="(child, i) in item.children"
            :key="i"
            :class="menuList.length - 1 === index ? child.styleClass : ''"
          >
            {{ child.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      lang: 'zh',
      hoverIndex: 0
    }
  },
  computed: {
    menuList() {
      return [
        { title: this.$i18n.t('navbar.home'), url: '/home', children: [] },
        {
          title: this.$i18n.t('navbar.carIntroduction'),
          url: '/car-introduction',
          children: [
            { title: this.$i18n.t('navbar.charteredCar'), url: '/car-introduction?type=2' }
          ]
        },
        {
          title: this.$i18n.t('navbar.carService'),
          url: '/car-service',
          children: [
            { title: this.$i18n.t('navbar.serviceConfiguration'), url: '/car-service?type=2' },
            { title: this.$i18n.t('navbar.serviceAdvantage'), url: '/car-service?type=3' }
          ]
        },
        {
          title: this.$i18n.t('navbar.addService'),
          url: '/add-service',
          children: [{ title: this.$i18n.t('navbar.exclusiveService'), url: '/add-service' }]
        },
        {
          title: this.$i18n.t('navbar.clubsSummary'),
          url: '/clubs-summary',
          children: [
            { title: this.$i18n.t('navbar.companyPhilosophy'), url: '/clubs-summary?type=1' },
            { title: this.$i18n.t('navbar.companyProfile'), url: '/clubs-summary?type=2' },
            { title: this.$i18n.t('navbar.serviceContent'), url: '/clubs-summary?type=4' }
          ]
        },
        {
          title: this.$i18n.t('navbar.contactUs'),
          url: '/contact-us',
          children: [
            { title: this.$i18n.t('navbar.onlineMessage'), url: '/contact-us' },
            { title: this.$i18n.t('navbar.joinUs'), url: '/contact-us?type=2' },
            { title: this.$i18n.t('navbar.commonQA'), url: '/contact-us?type=3' }
          ]
        },
        {
          title: this.$i18n.t('navbar.language'),
          children: [
            { title: '日本語', lang: 'ja', styleClass: 'irohamaru' },
            { title: 'English', lang: 'en', styleClass: 'inter' },
            { title: '中文（简体）', lang: 'zh', styleClass: 'sourceHanMonoSc' },
            { title: '中文（繁體）', lang: 'zhf', styleClass: 'sourceHanMonoTc' }
          ]
        }
      ]
    }
  },
  props: {
    name: {
      type: String,
      default: null
    },
    showLogo: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.lang = localStorage.getItem('language') || this.$i18n.locale
  },
  methods: {
    toMenu(item) {
      this.$router.push(item.url)
    },
    toChildMenu(parent, child) {
      if (parent.title === this.$i18n.t('navbar.language')) {
        this.lang = child.lang
        localStorage.setItem('language', child.lang)
        this.$i18n.locale = child.lang
        document.title = this.$i18n.t(`routeTitle.${this.$route.meta.key}`)
      } else {
        this.$router.push(child.url)
      }
    },
    hoverTitle(index) {
      this.hoverIndex = index
    },
    outTitle() {
      this.hoverIndex = 0
    }
  }
}
</script>

<style scoped lang="scss">
.navbar {
  position: fixed;
  z-index: 99;
  top: 0;
  color: #ffffff;
  height: 72px;
  width: calc(100vw - 140px);
  padding: 0 70px;
  .ltd {
    font-size: 22px;
    color: $primary-color;
    margin-left: 16px;
    animation: ltdShow 1s ease 200ms 1 normal forwards;
    opacity: 0;
    @keyframes ltdShow {
      100% {
        opacity: 1;
      }
    }
  }
  .menu-box {
    gap: 40px;
    .act {
      color: $primary-color;
    }
    .menu {
      width: 150px;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      cursor: pointer;
      position: relative;
      height: 72px;
      white-space: nowrap;
      .title {
        width: 150px;
        text-align: center;
        &:hover {
          color: $primary-color;
        }
      }
      .menu-children {
        position: absolute;
        top: 72px;
        //width: 124px;
        padding: 4px 12px;
        font-size: 20px;
        font-weight: 500;
        border-radius: 0 0 4px 4px;
        background: rgba(0, 0, 0, 0.4);
        .child-title {
          height: 43px;
          box-sizing: border-box;
          padding: 6px 0 8px;
          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }
  img {
    animation: small 1s ease 200ms 1 normal forwards;
    width: 3.125vw;
    height: 3.333333vw;
    opacity: 0;
    @keyframes small {
      0% {
        width: 3.125vw;
        height: 3.333333vw;
        margin-left: 30px;
        margin-top: 36px;
        opacity: 0;
      }
      100% {
        width: 1.979167vw;
        height: 2.109167vw;
        opacity: 1;
      }
    }
  }
  //img {
  //  width: 1.979167vw;
  //  height: 2.109167vw;
  //}
}
.zh,
.zhf {
  .menu-children {
    width: 124px;
    left: 1px;
  }
}
.ja {
  .menu {
    &:nth-child(2) {
      .menu-children {
        margin-left: -16px;
      }
    }
    &:nth-child(3) {
      .menu-children {
        margin-left: -10px;
      }
    }
    &:nth-child(4) {
      .menu-children {
        margin-left: 3px;
      }
    }
    &:nth-child(5) {
      .menu-children {
        margin-left: 3px;
      }
    }
    &:nth-child(6) {
      .menu-children {
        margin-left: -37px;
      }
    }
    &:nth-child(7) {
      .menu-children {
        margin-left: 3px;
      }
    }
  }
}
.en {
  .menu {
    &:nth-child(2) {
      .menu-children {
        margin-left: -44px;
      }
    }
    &:nth-child(3) {
      .menu-children {
        margin-left: -26px;
      }
    }
    &:nth-child(4) {
      .menu-children {
        margin-left: -16px;
      }
    }
    &:nth-child(5) {
      .menu-children {
        margin-left: -30px;
      }
    }
    &:nth-child(6) {
      .menu-children {
        margin-left: -46px;
      }
    }
    &:nth-child(7) {
      .menu-children {
        margin-left: 5px;
      }
    }
  }
}
</style>

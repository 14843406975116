// jpa.js
export default {
  home: {
    title: 'トップページです',
    slogan: '安全第一\n' + '最高のサービス\n' + 'お客様の声が最優先\n' + '最高のくつろぎ',
    useCar: '車を使う',
    aboutUs: {
      title: 'DDについて',
      content1:
        '「お客様の声を最優先にし、最高のサービスを提供する事」を理念に、プロフェッショナルで高品質、安全できめ細やかなサービスをご提供いたします。',
      content2:
        '株式会社DDハイヤーは東京を拠点に10年以上の経験を持つハイヤーサービス会社です。 様々なニーズにお応えできるよう、幅広い車種を取り揃えております。全車両にハイエンドな機能を搭載し、お客様の安全を確保するため、定期的に点検を行っております。ドライバーとスタッフは厳しいトレーニングを経てから勤務しておりますので、安心・安全なサービスをご提供できます。',
      content3:
        '株式会社DDハイヤーはグローバル的な視点を持ち、多国籍のスタッフが在籍しております。日本語、英語、中国語、韓国語などの言語でサービスを提供でき、海外のお客様も安心してご利用いただけます。',
      content4:
        '空港送迎、出張利用、観光利用、ゴルフ送迎、その他用途やチャーター便を手配できます。また、DDツーリズム株式会社をバックアップにし、プロフェッショナルな日本ツアーサービスなどをご提供できます。'
    }
  },
  navbar: {
    home: 'トップ',
    carIntroduction: '車種紹介',
    carService: 'ハイヤーサービス',
    addService: '旅行手配',
    clubsSummary: '会社概要',
    contactUs: 'お問い合わせ',
    language: '言語切り替え',
    charteredCar: '日本全国ハイヤー',
    serviceConfiguration: 'サービス',
    serviceAdvantage: 'サービスの優位性',
    exclusiveService: '専属サービス',
    companyPhilosophy: '会社理念',
    companyProfile: '会社概要',
    serviceContent: 'サービス内容',
    onlineMessage: 'オンラインメッセージ',
    joinUs: 'グループ加入',
    commonQA: 'よくある質問',
    latest: '最新のプッシュ',
    companyName: '株式会社DDハイヤー',
    address: '会社住所'
  },
  routeTitle: {
    home: 'DD専用車です',
    carIntroduction: '車種案内で寸',
    carService: 'カ-サ-ビスです',
    addService: '旅行手配',
    clubsSummary: '会社概要です',
    contactUs: '連絡してきます',
    pushDetails: 'プッシュ詳細'
  },
  common: {
    language: '语言',
    submit: '送信',
    order: 'すぐ予約',
    onlineMsg: {
      process: 'サービスの手順',
      message: 'オンラインメッセージ',
      step1:
        'フォームに基本情報と連絡先を入力し、送信すると、カスタマーサービスがすぐに連絡します。また、直接お電話でお問い合わせいただくこともできます。',
      step2:
        'カスタマーサービスは、ご要望に合わせたサービス提供後の見積もりをご案内いたします。サービス内容と価格を確認した後、サービス料金を支払います。',
      step3:
        '安心のフルサービスで、ドライバーが積極的に連絡を取り、心地よいサポートを提供する、専用車両での上質な旅行をご提供します。'
    },
    successMsg: {
      title: '送信が成功しました'
    },
    formMsg: {
      baseInfo: '基本情報',
      rlus: '入力してください',
      rlusSelect: '選択してください',
      name: {
        label: '名前',
        placeholder: 'お名前を入力してください。例) 山田 太郎'
      },
      email: {
        label: '電子メール',
        placeholder: 'メールアドレスを入力してください。例) xxx@bytedance.com',
        rlus: '正しいメールアドレスの入力をお願いします'
      },
      tel: {
        label: '電話番号',
        placeholder: '海外の番号も対応しています。例) 120666131',
        rlus: '正しい電話番号をお願いします'
      },
      demand: '利用希望',
      address: {
        label: '出発地',
        placeholder: 'その他の場所は入力してください',
        option: ['成田空港', '羽田空港', '其他空港']
      },
      expectedDate: {
        label: '希望日',
        placeholder: '選択してください。年/月/日'
      },
      flight: {
        label: '予約便',
        placeholder: '例)NH104'
      },
      destination: {
        label: '目的地',
        placeholder: '例)東京都千代田区'
      },
      peopleNumber: {
        label: '人数',
        placeholder: '乗車人数を入力してください'
      },
      luggageNumber: {
        label: '荷物数',
        placeholder: '荷物数を入力してください'
      },
      contactInformation: {
        label: '連絡希望方法',
        placeholder: ''
      },
      pickUpService: {
        label: '接機服務',
        placeholder: 'お迎えサービスとは、ドライバーが名札を持って空港出口で待機するサービスです。'
      },
      childSeat: {
        label: 'チャイルドシート',
        placeholder: ''
      },
      language: {
        label: '英語/韓国語',
        placeholder: ''
      },
      remark: {
        label: '備考',
        placeholder: '他に要望がある場合は入力してください'
      },
      addService: '追加要望',
      exclusiveService: {
        label: '専用サービス',
        placeholder: ''
      },
      detailService: {
        label: '具体的な要求です',
        placeholder: '所定の時間、場所、要件などを入力します'
      }
    }
  },
  carIntroduction: {
    maximumPassengers: '最大乗用人数',
    maximumPassengersLabel: '乗客',
    maximumPassengersUnit: '人',
    highestPlacement: '最大荷物数',
    highestPlacementLabel: '荷物',
    highestPlacementUnit: '点',
    lowestPrice: '最低料金',
    otherBenefits: '他のメリット',
    swiper1: 'オーダーメイドの\n' + '旅程プラン',
    swiper2: '最高のくつろぎ',
    swiper3: '経験豊富なチーム\n' + 'による安心感',
    swiper4: '安全性と快適性のための\n' + '定期的な車検を実施'
  },
  carService: {
    title: 'ハイヤーサービス',
    swiper1: {
      label: '空港送迎',
      desc: '到着ロビーでお出迎えをする\nフライト遅延など万が一の心配不要'
    },
    swiper2: {
      label: '出張利用',
      desc: 'ビジネス用車、プロのドライバー、\n' + '車内無料WI-FI、旅行と仕事の両立。'
    },
    swiper3: {
      label: '観光利用',
      desc:
        '日本全国を効率的、快適、便利に移動できるので、\n' +
        '海外旅行で言葉の壁や不便な荷物、\n' +
        '不慣れなルートを心配する必要はありません。'
    },
    swiper4: {
      label: 'ゴルフ送迎 ',
      desc:
        '出発地からゴルフ場まで送迎、プレー中は運転手が待機しているので、コースが遠い、ゴルフバッグが重いなどの心配がなく、身軽にゴルフを楽しめます。'
    },
    swiper5: {
      label: 'その他サービス',
      desc:
        '旅程プランのオーダーメイド、ホテル予約代行、レストラン予約代行、スタジアム予約代行、チケット予約代行などのサービスもご提供します。'
    },
    img1: {
      label: '高級モデル',
      desc:
        '車両はすべて高級モデルであり、定期的にメンテナンスを行い、厳格な管理を行っております。安全性と快適性を確保するため、車両の更新も随時行っております。'
    },
    img2: {
      label: 'デラックスキャビン',
      desc:
        '車内は全てラグジュアリーな高級シートが備わっており、長距離の使用でも疲れにくく、快適にくつろいで頂けます。'
    },
    img3: {
      label: '清潔で快適',
      desc:
        '私たちは、1ゲスト1クリーン方式を導入しており、 全てのゲストが使用前に徹底的に車両を洗浄・消毒致します。'
    },
    img4: {
      label: 'プロのドライバー',
      desc:
        'ドライバーは全員、専門的で厳格なトレーニングを受けた者で、お客様に安心、親切、くつろぎをご提供致します。'
    },
    chooseUs: {
      title: 'なぜ私たち選ぶのか',
      text1: '全車種で無料Wi-Fi',
      text2: 'プロフェッショナルなトレーニング\n' + 'サービスファースト',
      text3: '安心、安全、快適',
      text4: '遅延などの緊急事態を心配する必要なし',
      text5: '到着ロビーでのお出迎えサービス'
    }
  },
  addService: {
    swiper1: {
      title: '旅程オーダーメイド',
      desc:
        'パーソナルな旅程で安心\n' +
        'の旅を。 専属の旅程オー\n' +
        'ダーメイド担当者、丁寧\n' +
        'なコミュニケーション、\n' +
        'ご自身のペースで日本の\n' +
        '旅をお楽しみください。'
    },
    swiper2: {
      title: 'ホテル予約代行',
      desc:
        '安心してご旅行いただけ\n' +
        'るよう、ホテルを事前に\n' +
        '代行予約致します。 お客\n' +
        '様のお好みに合わせてホ\n' +
        'テルをご提案し、最高の\n' +
        '滞在をお届けします。'
    },
    swiper3: {
      title: 'レストラン予約代行',
      desc:
        '日本ミシュラン、和食、\n' +
        'その他グルメを代行予約\n' +
        '致します。車での旅行と\n' +
        '同時に本格的な料理を楽\n' +
        'しんで頂けます。'
    },
    swiper4: {
      title: 'ゴルフ予約代行',
      desc:
        'ゴルフ場の利用を前もっ\n' +
        'て計画し、日本のゴルフ\n' +
        '場とサービスを楽しみ、\n' +
        'ゴルフの楽しさを満喫で\n' +
        'きます。'
    },
    swiper5: {
      title: '各種チケット\n' + '予約代行',
      desc:
        'あらゆるイベントチケッ\n' +
        'トの予約を網羅するワン\n' +
        'ストップサービスで、お\n' +
        '気に入りのイベントに簡\n' +
        '単に入場でき、便利で安\n' +
        '全にチケットをご購入頂\n' +
        'けます！'
    },
    info: 'その他のサービスについては、お問い合わせください'
  },
  clubsSummary: {
    info: {
      title: '株式会社DDハイヤー',
      desc:
        '私たちは東京に根付くプロフェッショナルハイヤー会社で、10年以上の業界経験を有し、\n' +
        '「サービス第一、アットホームのようなくつろぎ」という企業理念のもと、プロフェッショナルでハイエンド、\n' +
        '安全できめ細やかなサービス体験をご提供いたします。多国籍のスタッフが日本語、英語、中国語、韓国語などの言語で対応しますので、\n' +
        '海外のお客様にも安心してご利用いただけます。'
    },
    summary: {
      title: '会社概要',
      headQuarters: {
        label: '本社',
        content: '株式会社DDツーリズム'
      },
      representative: {
        label: '代表者',
        content: '望月 輝石'
      },
      headOffice: {
        label: '本社住所',
        content:
          '〒101-0021東京都千代田区外神田3丁目5番5号\n' +
          'TEL：03-6262-9696\n' +
          'FAX：03-6262-9630'
      },
      permitApprovalNumber: {
        label: '許可認可番号',
        content: '東京都知事登録旅行業2-8357'
      },
      managementCompany: {
        label: '運営会社',
        content: '株式会社DDハイヤー'
      },
      representative2: {
        label: '代表者',
        content: '淺井 漢鷹'
      },
      salesOffice: {
        label: '営業所',
        content:
          '〒133-0055東京都江戸川区西篠崎2丁目19番9号\n' +
          'TEL：03-5664-6122\n' +
          'FAX：03-5664-6132'
      },
      permitApprovalNumber2: {
        label: '許可認可番号',
        content: '関自旅ニ第762号'
      }
    },
    address: {
      title: '会社住所',
      info1: {
        name: '本社\n' + '株式会社DDツーリズム',
        detail: '東京都千代田区外神田3丁目5番5号',
        tel: 'TEL：03-6262-9696'
      },
      info2: {
        name: '営業所\n' + '株式会社DDハイヤー',
        detail: '東京都江戸川区西篠崎2丁目19番9号',
        tel: 'TEL：03-5664-6122'
      }
    },
    service: {
      title: 'ハイヤーサービス',
      line1: {
        swiper1: {
          title: '空港送迎',
          desc: '到着ロビーでお出迎えをする、フライト遅延など万が一も心配不要'
        },
        swiper2: {
          title: '出張利用',
          desc: 'ビジネス用車、プロのドライバー、車内無料WI-FI、旅行と仕事の両立。'
        },
        swiper3: {
          title: '観光利用',
          desc:
            '日本全国を効率的、快適、便利に移動できるので、海外旅行で言葉の壁や不便な荷物、不慣れなルートを心配する必要はありません。'
        },
        swiper4: {
          title: 'ゴルフ送迎',
          desc:
            '出発地からゴルフ場まで送迎、プレー中は運転手が待機しているので、コースが遠い、ゴルフバッグが重いなどの心配がなく、身軽にゴルフを楽しめます。'
        },
        swiper5: {
          title: 'その他サービス',
          desc:
            '旅程プランのオーダーメイド、ホテル予約代行、レストラン予約代行、スタジアム予約代行、チケット予約代行などのサービスもご提供します。'
        }
      },
      line2: {
        swiper1: {
          title: '旅程オーダーメイド',
          desc:
            'パーソナルな旅程で安心の旅を。 専属の旅程オーダーメイド担当者、丁寧なコミュニケーション、ご自身のペースで日本の旅をお楽しみください。'
        },
        swiper2: {
          title: 'ホテル予約代行',
          desc:
            '安心してご旅行いただけるよう、ホテルを事前に代行予約致します。 お客様のお好みに合わせてホテルをご提案し、最高の滞在をお届けします。'
        },
        swiper3: {
          title: 'レストラン予約代行',
          desc:
            '日本ミシュラン、和食、その他グルメを代行予約致します。車での旅行と同時に本格的な料理を楽しんで頂けます。'
        },
        swiper4: {
          title: 'ゴルフ予約代行',
          desc:
            'ゴルフ場の利用を前もって計画し、日本のゴルフ場とサービスを楽しみ、ゴルフの楽しさを満喫できます。'
        },
        swiper5: {
          title: '各種チケット\n' + '予約代行',
          desc:
            'あらゆるイベントチケットの予約を網羅するワンストップサービスで、お気に入りのイベントに簡単に入場でき、便利で安全にチケットをご購入頂けます！'
        }
      },
      title2: '専属サービス'
    }
  },
  pushDetails: {
    backPage: 'ホームページに戻る',
    recentPush: '最近のプッシュ',
    previous: '前ページ',
    next: '次ページ'
  },
  contactUs: {
    JoinUs: 'グループ加入',
    commonQA: 'よくある質問'
  },
  footer: {
    address: '〒101-0021東京都千代田区外神田3丁目5番5号'
  }
}

/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn, delay) {
  let timer
  return function() {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(function() {
      fn.apply(context, args)
    }, delay)
  }
}

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
  if (!time) return ''
  else {
    const date = new Date(time)
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds()
    }
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
        )
      }
    }
    return fmt
  }
}

/**
 * @param {string} time
 * @param {string} option
 * @returns {string}
 */
export function formatTimeText(time, option) {
  const d = new Date(time)
  const now = Date.now()
  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日'
  }
}

/**
 *
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
}

/**
 * @param {string} time
 * @returns {string}
 */
export function formatTimePrefix(time) {
  let d = new Date(parseTime(time, '{y}-{m}-{d}'))
  let now = new Date(parseTime(Date.now(), '{y}-{m}-{d}'))
  let yesterday = new Date(new Date().setHours(0) - 24 * 60 * 60 * 1000)
  let ye = new Date(parseTime(yesterday, '{y}-{m}-{d}'))
  let prefix = ''
  if (d.getTime() === now.getTime()) {
    prefix = '今天'
  } else if (d.getTime() === ye.getTime()) {
    prefix = '昨天'
  }
  return prefix + ' ' + parseTime(time, '{y}-{m}-{d}')
}

//新闻详情打开新窗口
export function gotoNewsDialog(articleId) {
  let routeData = this.$router.resolve({
    name: 'NewsDetail',
    query: { articleId: articleId }
  })
  window.open(routeData.href, '_blank')
}

const repair = i => {
  return i < 10 ? '0' + i : i
}
/**
 * 获取当前日期（默认）、时间、星期
 * type：date、time、week
 * sub：相差天数，-1前一天 1后一天
 * time：时间基准
 */
function getNow(type, sub, time) {
  let str, date, y, M, d, H, m, s, w
  if (!time) {
    date = new Date()
  } else {
    date = new Date(time)
  }
  if (sub) {
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * sub)
  }
  y = date.getFullYear()
  M = repair(date.getMonth() + 1)
  d = repair(date.getDate())
  H = repair(date.getHours())
  m = repair(date.getMinutes())
  s = repair(date.getSeconds())
  w = date.getDay()
  switch (type) {
    case 'week':
      str = '星期' + '日一二三四五六'.charAt(w)
      break
    case 'time':
      str = `${y}-${M}-${d} ${H}:${m}:${s}`
      break
    default:
      str = `${y}-${M}-${d}`
      break
  }
  return str
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
  let config = {
    id: id || 'id',
    parentId: parentId || 'parentId',
    childrenList: children || 'children'
  }

  const childrenListMap = {}
  const nodeIds = {}
  const tree = []

  for (let d of data) {
    let parentId = d[config.parentId]
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = []
    }
    nodeIds[d[config.id]] = d
    childrenListMap[parentId].push(d)
  }

  for (let d of data) {
    let parentId = d[config.parentId]
    if (nodeIds[parentId] == null) {
      tree.push(d)
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t)
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]]
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c)
      }
    }
  }
  return tree
}

// 验证是否为blob格式
export async function blobValidate(data) {
  try {
    const text = await data.text()
    JSON.parse(text)
    return false
  } catch (error) {
    return true
  }
}

// 获取图片展示路径
function getImgUrl(url) {
  return process.env.VUE_APP_BASE_API + url
}

// 打开新窗口
function openWindow(router, routeData) {
  let data = router.resolve(routeData)
  window.open(data.href, '_blank')
}

export default {
  getNow,
  getImgUrl,
  openWindow,
  handleTree,
  formatTimeText,
  blobValidate
}

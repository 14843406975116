import { login, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    userId: '',
    sex: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_USERID: (state, userId) => {
      state.userId = userId
    },
    SET_SEX: (state, sex) => {
      state.sex = sex
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password
      const from = userInfo.from
      setToken(null)
      return new Promise((resolve, reject) => {
        login(username, password, from)
          .then(res => {
            setToken(res.token)
            commit('SET_TOKEN', res.token)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then(res => {
            const user = {
              userName: res.data.nickName,
              avatar: res.data.avatar,
              userId: res.data.userId,
              sex: res.data.sex
            }
            const avatar =
              user.avatar == '' || user.avatar == null
                ? ''
                : process.env.VUE_APP_BASE_API + user.avatar
            commit('SET_NAME', user.userName)
            commit('SET_AVATAR', avatar)
            commit('SET_USERID', user.userId)
            commit('SET_SEX', user.sex)
            resolve(res)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_NAME', '')
            commit('SET_AVATAR', '')
            commit('SET_USERID', '')
            removeToken()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user

const state = {
  keywords: ''
}

const mutations = {
  CHANGE_KEYWORDS: (state, mode) => {
    state.keywords = mode
  }
}

const actions = {
  changeKeywords({ commit }, navMode) {
    commit('CHANGE_KEYWORDS', navMode)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import request from '@/utils/request'

// 登录方法
export function login(username, password, from) {
  const data = {
    username,
    password,
    from
  }
  return request({
    url: '/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
